<template>
	
	<el-container style="height: 100%;">
		
		<!-- 左侧 -->
		<el-aside :width="fold?'auto':'170px'" v-if="user_info.tel !='13773336244'">
			
			<!-- 深蓝色背景  #00161d  color #fff -->
			<el-menu 
				class="main_menu" 
				@select="select" 
				:collapse="fold" 
				:unique-opened="true" 
				:collapse-transition="false" 
				:default-active="currentMenu"
			>
				
				<!-- 左侧菜单 -->
				<el-submenu v-for="(group,idx) in menu" :key="idx" :index="idx.toString()" >
					
					<!-- 菜单组 -->
					<template slot="title">
						<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
						&nbsp;&nbsp;
						<span slot="title">{{group.name}}</span>
					</template>

					<!-- 单个菜单 -->
					<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index" v-if="(item.name=='支付记录'&&not_pay_tels.indexOf(user_info.tel)>-1)?false:true">{{item.name}}</el-menu-item>

					<!-- <el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index" v-if="(not_type_tels.indexOf(item.name)>-1 && not_pay_tels.indexOf(user_info.tel)>-1)?false:true">{{item.name}}</el-menu-item> -->
				</el-submenu>

			</el-menu>
		</el-aside>
		<!-- 左侧(只读) -->
		<el-aside :width="fold?'auto':'170px'" v-if="user_info.tel =='13773336244'">
			
			<!-- 深蓝色背景  #00161d  color #fff -->
			<el-menu 
				class="main_menu" 
				@select="select1" 
				:collapse="fold" 
				:unique-opened="true" 
				:collapse-transition="false" 
				:default-active="currentMenu"
			>
				
				<!-- 左侧菜单 -->
				<el-submenu v-for="(group,idx) in menu1" :key="idx" :index="idx.toString()">
					
					<!-- 菜单组 -->
					<template slot="title">
						<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
						&nbsp;&nbsp;
						<span slot="title">{{group.name}}</span>
					</template>

					<!-- 单个菜单 -->
					<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index" v-if="(item.name=='支付记录'&&not_pay_tels.indexOf(user_info.tel)>-1)?false:true">{{item.name}}</el-menu-item>

					<!-- <el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index" v-if="(not_type_tels.indexOf(item.name)>-1 && not_pay_tels.indexOf(user_info.tel)>-1)?false:true">{{item.name}}</el-menu-item> -->
				</el-submenu>

			</el-menu>
		</el-aside>
		<!-- 右边 -->
		<el-container class="right_container">

			<!-- 头部一行 -->
			<el-header class="right_header" style="height:50px">
				<div class="left" @click="menu_toggle">
					<i :class="'text-xxl '+(fold?'el-icon-s-unfold':'el-icon-s-fold')"></i>
				</div>
				<div class="right">
					<div class="text">{{main_user_company_name}} &nbsp;&nbsp;&nbsp; {{main_user_name}}</div>
					<div class="el-icon-switch-button logout_icon" @click="logout" ></div>
				</div>
			</el-header>

			<!-- 内容区域 -->
			<div class="container">
				<router-view></router-view>
			</div>
		</el-container>
	</el-container>
</template>
<script>
	import {mapMutations,mapState} from 'vuex'
	export default {
		data() {
			return {

				//菜单是否折叠
				fold: false,

				//选中菜单
				currentMenu: '',
				
				//主用户公司名称
				main_user_company_name:'',
				
				//主用户姓名
				main_user_name:'',
				
				//这些子账户登录时隐藏支付记录
				not_pay_tels:['18672692607','13377857911','18696415263','15171431533','15971456412','18162681383','18819339971','18855370372'],
				
				not_type_tels:['添加线路','运单列表','线路列表','用户信息'],
				
				//重要:这里需要解决 两种子账号(全数据/部分数据) 不同显示问题 2021-4-30
				
				
				//菜单数据
				menu: [{
						name:'个人中心',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'用户信息',
								url:'sub_user/user_info'
							},
							// {
							// 	name:'流水列表',
							// 	url:'sub_user/all_ra_list'
							// },
							// {
							// 	name:'充值记录',
							// 	url:'sub_user/recharge_list'
							// }
						]
					},
					{
						name:'注册信息',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'货车列表',
								url:'truck_list'
							},
							{
								name:'司机列表',
								url:'driver_list'
							},
						]
					},
					{
						name:'线路管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'添加线路',
								url:'business_line/bl_creat'
							},{
								name:'线路列表',
								url:'business_line/bl_list'
							}
						]
					},{
						name:'货源管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'发布货源',
								url:'cargo/cargo_creat'
							},{
								name:'货源列表',
								url:'cargo/cargo_list'
							}
						]
					},{
						name:'运单管理',
						icon:'el-icon-s-order',
						children:[
							{
								name:'运单列表',
								url:'tord/tord_list'
							},{
								name:'待接单',
								url:'tord/tord_deal'
							},{
								name:'运输中',
								url:'tord/tord_act'
							},{
								name:'已结束',
								url:'tord/tord_end'
							},{
								name:'支付计划',
								url:'tord/tord_wait_list_plan'
							},
							// {
							// 	name:'待支付',
							// 	url:'tord/tord_wait_pay_start'
							// },{
							// 	name:'已支付',
							// 	url:'tord/tord_wait_pay_end'
							// },
							{
								name:'可开票',
								url:'tord/tord_invoice_end'
							},
							{
								name:'支付记录',
								url:'tord/tord_pay_list'
							},
								{
								name:'批量修改运费',
								url:'tord_real_time/tord_batch_edit'
							},
							{
								name:'批量修改运单',
								url:'tord_real_time/batch_tord_edit'
							},
						]
					},
					
					// {
					// 	name:'后录单',
					// 	icon:'el-icon-s-finance',
					// 	children:[
					// 		{
					// 			name:'excel录入',
					// 			url:'tord_after_time/event_add_by_excel'
					// 		},{
					// 			name:'录入记录',
					// 			url:'tord_after_time/event_list'
					// 		},{
					// 			name:'待审核',
					// 			url:'tord_after_time/tord_before_check'
					// 		},{
					// 			name:'审核通过',
					// 			url:'tord_after_time/tord_check_pass'
					// 		},{
					// 			name:'审核驳回',
					// 			url:'tord_after_time/tord_check_unpass'
					// 		},{
					// 			name:'开票申请',
					// 			url:'tord_after_time/invoice_apply'
					// 		},{
					// 			name:'开票完成',
					// 			url:'tord_after_time/invoice_done'
					// 		},{
					// 			name:'开票失败',
					// 			url:'tord_after_time/invoice_revoke'
					// 		}
					// 	]
					// }
				],

				//上海杨腾菜单数据 只读
				menu1: [{
						name:'个人中心',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'用户信息',
								url:'sub_user/user_info'
							},
						]
					},
					{
						name:'线路管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'添加线路',
								url:'business_line/bl_creat'
							},{
								name:'线路列表',
								url:'tord_real_time/bl_read_list'
							}
						]
					},
					{
						name:'货源管理',
						icon:'el-icon-s-goods',
						children:[{
								name:'发布货源',
								url:'cargo/cargo_creat'
							},{
								name:'货源列表',
								url:'tord_real_time/cargo_read_list'
							}
						]
					},
					{
						name:'运单管理',
						icon:'el-icon-s-order',
						children:[
							{
								name:'运单列表',
								url:'tord_real_time/tord_read_list'
							},
							// {
							// 	name:'待接单',
							// 	url:'tord/tord_deal'
							// },{
							// 	name:'运输中',
							// 	url:'tord/tord_act'
							// },{
							// 	name:'已结束',
							// 	url:'tord/tord_end'
							// },{
							// 	name:'支付计划',
							// 	url:'tord/tord_wait_list_plan'
							// },
							// {
							// 	name:'可开票',
							// 	url:'tord/tord_invoice_end'
							// },
							// {
							// 	name:'支付记录',
							// 	url:'tord/tord_pay_list'
							// }
						]
					},
				],
			}
		},
		computed:{
			...mapState(['main_user_info','user_info'])
		},
		created() {
			
			//页面初始化
			this.init()
			//默认选中
			//this.openDefault()

			//折叠主账号菜单
			this.set_main_aside_fold(true)
		},
		methods: {

			...mapMutations(['end_of_sub_user_logout','set_main_aside_fold']),
			//菜单展开折叠
			menu_toggle() {
				this.fold = this.fold ? false : true

				if(this.fold){
					localStorage.setItem('_pages_index_fold',1);
				}else{
					localStorage.setItem('_pages_index_fold',2);
				}
			},
			
			//菜单跳转
			select(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},

			//菜单跳转 上海杨腾
			select1(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu1[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},
			//退出登录
			logout(){
				this.$my.other.confirm({
					content:'退出主账户登录,请确认?',
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_sub_user',
								ctr:'sub_user_logout_from_main_user',
							},
							callback:(data)=>{

								//删除缓存
								this.end_of_sub_user_logout();

								//跳转回列表页
								this.$router.push('/pages/sub_user/my_main_user_list');
							}
						});
					}
				});
			},
			
			//初始化
			init(){
				
				//取出数据
				let main_user_info=this.main_user_info
				
				//置入用户名和公司名
				this.main_user_company_name=main_user_info.main_user_company_name
				this.main_user_name=main_user_info.main_user_name
			},

			//展开并选中当前路由菜单
			openDefault() {
				var menu = this.menu
				for (var i in menu) {
					for (var j in menu[i].children) {
						var path = menu[i].children[j].url.replace("#", "")
						if (path == this.$route.path) {
							this.currentMenu = `${i}-${j}` 
						}
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.logo {
		text-align: center;
		padding: 15px 0 0 0;
		img {
			width: 60px;
			padding: 10px 0px;
		}
	}

	.main_menu {
		height: 100%;
		overflow-x: hidden;
	}

	.right_container{
		padding: 0;

		.right_header{
			padding: 0;
			height: 50px;
			line-height: 50px;
			display: flex;
			justify-content: space-between;
			background-color: #fff;

			.left{
				cursor: pointer;
				color: #666;
				width: 50px;
				text-align: center;
			}
			.right{
				color: #666;
				display: flex;
				padding-right: 15px;
				.logout_icon{
					cursor: pointer;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					font-weight:600;
				}
			}
		}

		.container{
			background-color:#aaa;
			padding: 5px;
			overflow-y: auto;
			border: 5px solid #fff;
			min-height: calc(100% - 75px);
			border-width: 0 15px 15px 0;
		}
	}
	
</style>